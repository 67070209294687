@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600&family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@keyframes rotateInReversed {
  from {
    transform: rotate3d(0, 0, 1, -180deg);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInReversed {
  animation-name: rotateInReversed;
  transform-origin: center;
}

@keyframes delayedFadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.delayedFadeInUp {
  animation-name: delayedFadeInUp;
  animation-delay: 100ms;
}

@keyframes delayedFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.delayedFadeIn {
  animation-name: delayedFadeIn;
  animation-delay: 500ms;
}

.bullet {
  list-style-image: url('/public/assets/bullet.svg');
}
